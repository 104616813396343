import React, { Component } from 'react';
import classNames from 'classnames';

class ApplicationForm extends Component {
  state = {
    fields: {},
    loading: false,
  };
  componentDidMount() {
    window.mr.forms.documentReady(window.jQuery);
  }
  handleEncode = (data) => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  };
  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (target.type === 'file') {
      value = target.files[0];
    } else {
      value = target.value;
    }
    this.setState({ fields: { ...this.state.fields, [name]: value } });
  };
  handleSubmit = (e) => {
    const form = e.target;
    // Use Stack's form validation
    if (mr.forms.validateFields(form) === 1) {
      e.preventDefault();
      return;
    }
    // Stop loading
    this.setState({ loading: true });
    // Send form data
    fetch(process.env.GATSBY_FORM_ENDPOINT, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: this.handleEncode({
        _template: 'career-notification',
        ...this.state.fields,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        // Reset form
        this.setState({ fields: {}, loading: false });
        // Reset file input field
        this._resume.value = '';
        // Show success notification
        const formError = jQuery('body').find('.form-error');
        const formSuccess = jQuery('body').find('.form-success');
        mr.forms.showFormSuccess(formSuccess, formError, 1000, 5000, 500);
      })
      .catch((error) => {
        const formError = jQuery('body').find('.form-error');
        const formSuccess = jQuery('body').find('.form-success');
        // If error text was returned, put the text in the .form-error div and show it.
        // Keep the current error text in a data attribute on the form
        formError.attr('original-error', formError.text());
        // Show the error with the returned error text.
        formError.text(error).stop(true).fadeIn(1000);
        formSuccess.stop(true).fadeOut(1000);
      });
    e.preventDefault();
  };
  render() {
    const { loading, fields } = this.state;
    const {
      data: { page, careers },
    } = this.props;
    return (
      <div className="feature feature-large">
        <div className="feature__body">
          <div className="text-block">
            <h3>{page.frontmatter.applicationTitle}</h3>
            <p>{page.frontmatter.applicationDescription}</p>
          </div>
          <form
            name="application"
            method="post"
            autoComplete="off"
            className="text-left form-email row"
            data-success="Thanks for your request, we'll be in touch shortly."
            data-error="Please fill in all fields correctly."
            onSubmit={this.handleSubmit}>
            <span hidden>
              <label>
                Don’t fill this out:
                <input name="gotcha" onChange={this.handleChange} />
              </label>
            </span>
            <div className="col-md-6">
              <span>Name:</span>
              <input
                type="text"
                name="name"
                className="validate-required"
                onChange={this.handleChange}
                value={fields.name || ''}
              />
            </div>
            <div className="col-md-6">
              <span>Location:</span>
              <input
                type="text"
                name="location"
                className="validate-required"
                onChange={this.handleChange}
                value={fields.location || ''}
              />
            </div>
            <div className="col-md-6">
              <span>Email Address:</span>
              <input
                type="email"
                name="email"
                className="validate-required validate-email"
                onChange={this.handleChange}
                value={fields.email || ''}
              />
            </div>
            <div className="col-md-6">
              <span>Phone:</span>
              <input
                type="tel"
                name="phone"
                className="validate-required"
                onChange={this.handleChange}
                value={fields.phone || ''}
              />
            </div>
            <div className="col-md-12">
              <span>Select a Career:</span>
              <div className="input-select">
                <select
                  name="career"
                  value={fields.career || ''}
                  onChange={this.handleChange}>
                  <option value="" />
                  {careers.edges.map(({ node }) => {
                    return (
                      <option value={node.frontmatter.title} key={node.id}>
                        {node.frontmatter.title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <span>Comments:</span>
              <textarea
                rows="4"
                name="comments"
                className="validate-required"
                onChange={this.handleChange}
                value={fields.comments || ''}
              />
            </div>
            <div className="col-md-12">
              <span style={{ marginRight: '1em' }}>Upload Resumé:</span>
              <input
                type="file"
                name="resume"
                onChange={this.handleChange}
                ref={(ref) => (this._resume = ref)}
              />
              {/* <input
                data-locale={process.env.GATSBY_UPLOADCARE_LOCALE}
                data-tabs={process.env.GATSBY_UPLOADCARE_TABS}
                data-crop="disabled"
                type="hidden"
                role="uploadcare-uploader" // eslint-disable-line
                name="resume"
                ref={ref => (this._file = ref)}
              /> */}
            </div>
            <div className="col-md-12 boxed">
              <button
                type="submit"
                className={classNames('btn btn--primary type--uppercase', {
                  'btn--loading': loading,
                })}>
                Submit Application
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ApplicationForm;
