import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Content from '../components/Content';
import ApplicationForm from '../components/ApplicationForm';
import Layout from '../components/Layout';
import styled from 'styled-components';

const Careers = styled('section')`
  .tabs-content {
    margin: 0;
  }
  ul ul {
    margin-bottom: 1.6em;
  }
`;

class Page extends Component {
  state = {
    selectedTab: 0,
  };
  handleTabs = index => () => {
    this.setState({ selectedTab: index });
  };
  renderCareers = () => {
    const {
      data: { careers },
    } = this.props;
    return careers.edges.map(({ node }) => {
      return (
        <div className="col-12 boxed boxed--border">
          <h4>{node.frontmatter.title}</h4>
          <Content content={node.html} />
        </div>
      );
    });
  };
  render() {
    const { selectedTab } = this.state;
    const {
      data: { page, careers },
    } = this.props;
    return (
      <Layout page={page}>
        <section className="space--sm border--bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <Content content={page.html} />
              </div>
            </div>
          </div>
        </section>
        <Careers className="space--sm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tabs-container tabs--vertical">
                  <ul className="tabs">
                    {careers.edges.map(({ node }, i) => {
                      return (
                        <li
                          className={i === selectedTab ? 'active' : ''}
                          key={node.id}
                          onClick={this.handleTabs(i)}
                        >
                          <div className="tab__title">
                            <span className="h5">{node.frontmatter.title}</span>
                          </div>
                        </li>
                      );
                    })}
                    <li
                      key="apply-now"
                      style={{ opacity: 1 }}
                      onClick={this.handleTabs(careers.edges.length)}
                    >
                      <button
                        className="btn btn--primary type--uppercase"
                        style={{ width: '100%' }}
                      >
                        <span className="h5 text--center btn__text">
                          Apply Now
                        </span>
                      </button>
                    </li>
                  </ul>
                  <ul className="tabs-content">
                    {careers.edges.map(({ node }, i) => {
                      return (
                        <li
                          className={i === selectedTab ? 'active' : ''}
                          key={node.id}
                        >
                          <div className="tab__content">
                            <h3>{node.frontmatter.title}</h3>
                            <Content content={node.html} />
                          </div>
                        </li>
                      );
                    })}
                    <li
                      className={
                        selectedTab === careers.edges.length
                          ? 'active'
                          : 'hidden'
                      }
                    >
                      <div className="tab__content">
                        <ApplicationForm data={{ page, careers }} />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Careers>
      </Layout>
    );
  }
}

export default Page;

export const query = graphql`
  query GetCareersPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metaTitle
        metaDescription
      }
    }
    careers: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "careers" }, status: { eq: "active" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;
